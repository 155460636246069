import lazyWithRetry from 'tools/lazyWithRetry';

const InterventionTaskOperationExtraListPage = lazyWithRetry(() => import(
	'../../../pages/intervention/InterventionTaskOperationExtraListPage'));

export default [
	{
		component: InterventionTaskOperationExtraListPage,
		exact: true,
		path: '/interventions_task_extra_operations',
	},
];
