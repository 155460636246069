import FileModel                          from 'Models/file/FileModel';
import AccountingCodeModel                from 'Models/invoice/AccountingCodeModel';
import PartitionModel                     from 'Models/partition/PartitionModel';
import SupplierCompanyModel               from 'Models/supplier/SupplierCompanyModel';
import AmenityModel                       from 'Models/vehicle/AmenityModel';
import DressingModel                      from 'Models/vehicle/DressingModel';
import EnergyModel                        from 'Models/vehicle/EnergyModel';
import FundingVehicleModel                from 'Models/vehicle/FundingVehicleModel';
import InsuranceContractVehicleModel      from 'Models/vehicle/InsuranceContractVehicleModel';
import VehicleBrandModel                  from 'Models/vehicle/VehicleBrandModel';
import VehicleMileageModel                from 'Models/vehicle/VehicleMileageModel';
import VehicleModelModel                  from 'Models/vehicle/VehicleModelModel';
import VehicleOwnerModel                  from 'Models/vehicle/VehicleOwnerModel';
import VehicleSubPartitionAssignmentModel from 'Models/vehicle/VehicleSubPartitionAssignmentModel';
import VehicleTypeModel                   from 'Models/vehicle/VehicleTypeModel';
import VehicleVersionModel                from 'Models/vehicle/VehicleVersionModel';
import doc                                from 'decorators/doc';
import model                              from 'decorators/model';
import { Timestampable }                  from 'helpers/traits';
import { Blamable }                       from 'helpers/traits';
import { IVehicleNameProps }              from 'modelNames/vehicle/VehicleName';
import VehiclePrivateApiModel             from 'modelx/models/abstracts/VehiclePrivateApiModel';
import moment                             from 'moment';
import { whenAsync }                      from 'tools/modelxTools';

@model.staticLabel('Véhicule')
@model.urnResource('vehicle')
@doc.path('/vehicles/{?id}')
export default class VehicleModel extends Blamable(Timestampable(VehiclePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'acquisitionDate[after]': string | Date;
		'acquisitionDate[before]': string | Date;
		'acquisitionDate[strictly_after]': string | Date;
		'acquisitionDate[strictly_before]': string | Date;
		'archived': '0' | '1';
		'deliveryDate[after]': string | Date;
		'deliveryDate[before]': string | Date;
		'deliveryDate[strictly_after]': string | Date;
		'deliveryDate[strictly_before]': string | Date;
		'energy': id;
		'firstCirculationDate[after]': string | Date;
		'firstCirculationDate[before]': string | Date;
		'firstCirculationDate[strictly_after]': string | Date;
		'firstCirculationDate[strictly_before]': string | Date;
		'fiscalPower': string;
		'fundingVehicles.funding': id;
		'fundingVehicles.funding.invoicingMode': id;
		'fundingVehicles.vehicle': id;
		'licensePlate': string;
		'registrationDate[after]': string | Date;
		'registrationDate[before]': string | Date;
		'registrationDate[strictly_after]': string | Date;
		'registrationDate[strictly_before]': string | Date;
		'vehicleBrand': id;
		'vehicleLeaseholders.leaseholder': id;
		'vehicleModel': id;
		'vehicleSubPartitions': id;
		'vehicleSubPartitions.ownerSubPartition.partitionUrn': Urn;
		'vehicleSubPartitions.ownerSubPartition.subPartitionUrn': Urn;
		'vehicleSubPartitions.vehicleSubPartitionAssignments': id;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.accountingAnalyticCodeUrn': Urn;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.endDate[after]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.endDate[before]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.endDate[strictly_after]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.endDate[strictly_before]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.staffMemberUrn': Urn;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.startDate[after]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.startDate[before]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.startDate[strictly_after]': string | Date;
		'vehicleSubPartitions.vehicleSubPartitionAssignments.startDate[strictly_before]': string | Date;
		'vehicleType': id;
		'vehicleType.reference': VehicleVehicleTypeReference;
		'vehicleVersion': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'acquisitionDate': string;
		'deliveryDate': string;
		'firstCirculationDate': string;
		'id': string;
		'licensePlate': string;
		'name': string;
		'registrationDate': string;
		'updatedAt': string;
		'vehicleType.label': string;
		'vehicleType.position': string;
	}> = {};

	@doc.date declare acquisitionDate?: Moment;
	@doc.model(AmenityModel) declare amenity: AmenityModel;
	@doc.boolean declare archived: boolean;
	@doc.string declare carbonDioxide: string;
	@doc.date declare dateOut?: Moment;
	@doc.date declare deliveryDate?: Moment;
	@doc.model(DressingModel) declare dressing: DressingModel;
	@doc.model(EnergyModel) declare energy: EnergyModel;
	@doc.date declare firstCirculationDate?: Moment;
	@doc.string declare fiscalPower: string;
	@doc.string declare licensePlate: string;
	@doc.string declare name: string;
	@doc.number declare number: number;
	@doc.modelUrn(PartitionModel) declare partition: PartitionModel;
	@doc.date declare registrationDate?: Moment;
	@doc.string declare serialNumber: string;
	@doc.model(VehicleBrandModel) declare vehicleBrand: VehicleBrandModel;
	@doc.model(VehicleModelModel) declare vehicleModel: VehicleModelModel;
	@doc.model(VehicleTypeModel) declare vehicleType: VehicleTypeModel;
	@doc.model(VehicleVersionModel) declare vehicleVersion: VehicleVersionModel;

	@model.reverseResolvable<VehicleMileageModel>({
		singleRequest: false,
		sortWay: 'desc',
		urn: '$:vehicle:vehicle_mileage',
	})
	public declare currentMileage: VehicleMileageModel;

	@model.reverseResolvable<VehicleSubPartitionAssignmentModel, VehicleModel>({
		filters: (models) => ({
			'endDate[after]': moment().format('YYYY-MM-DD[T]23:59:59'),
			'startDate[before]': moment().format('YYYY-MM-DD[T]00:00:00'),
			'vehicleSubPartition.vehicle': models.map(m => m.urn),
		}),
		mapping: async (m1, m2) => {
			await whenAsync(() => m2.vehicleSubPartition.isLoaded);
			return m1.urn === m2.vehicleSubPartition.getUrn('vehicle');
		},
		urn: '$:vehicle:vehicle_sub_partition_assignment',
	})
	public declare currentAssignment: VehicleSubPartitionAssignmentModel;

	@model.reverseResolvable<VehicleOwnerModel, VehicleModel>({
		filters: (models) => ({
			'endDate[after]': moment().format('YYYY-MM-DD[T]23:59:59'),
			'startDate[before]': moment().format('YYYY-MM-DD[T]00:00:00'),
			'vehicle': models.map(m => m.urn),
		}),
		mapping: async (m1, m2) => {
			await whenAsync(() => m2.isLoaded);
			return m1.urn === m2.getUrn('vehicle');
		},
		urn: '$:vehicle:vehicle_owner',
	})
	public declare currentVehicleOwner: VehicleOwnerModel;

	@model.nestedResolvable()
	public get currentSubPartition() {
		return this.currentAssignment.subPartition;
	}

	@model.nestedResolvable()
	public get currentPartition() {
		return this.currentSubPartition.partition;
	}

	@model.nestedResolvable()
	public get currentDriver() {
		return this.currentVehicleOwner.staffMember;
	}

	@model.fileResolvable('vehicle_picture')
	public declare photo: FileModel;

	@model.reverseResolvable<FundingVehicleModel>({ urn: '$:vehicle:funding_vehicle' })
	public declare fundingVehicle: FundingVehicleModel;

	@model.reverseResolvable<FundingVehicleModel>({
		singleRequest: false,
		urn: '$:vehicle:insurance_contract_vehicle',
	})
	public declare firstInsuranceContractVehicle: InsuranceContractVehicleModel;

	@model.nestedResolvable()
	public get acquisitionType() {
		return this.fundingVehicle.funding.acquisitionType;
	}

	@model.nestedResolvable()
	public get supplierCompany() {
		return this.fundingVehicle.funding.supplierCompany;
	}

	@model.nestedResolvable()
	public get holder() {
		return this.fundingVehicle.funding.holder;
	}

	@model.nestedResolvable()
	public get currentVehicleSubPartition() {
		return this.currentAssignment.vehicleSubPartition;
	}

	public renderName(props?: Omit<IVehicleNameProps, 'model'>) {
		return super.renderName(props);
	}

	@model.resolvableUrn({ attributeName: 'supplierUrn' })
	public declare supplier: SupplierCompanyModel;

	@doc.modelUrn(AccountingCodeModel) declare maintenanceAccountingCode: AccountingCodeModel;
	@doc.modelUrn(AccountingCodeModel) declare rentAccountingCode: AccountingCodeModel;
}
